.adyen-checkout__phone-input .adyen-checkout__input-wrapper {
  width: 100%; }
  .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input {
    padding: 0;
    height: auto; }
    .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input:focus {
      border: 1px solid #06f;
      box-shadow: 0 0 0 2px #99c2ff; }
  .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button {
    width: auto;
    border: 0;
    height: 35px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
    .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button:after {
      left: 40px;
      box-sizing: revert;
      height: 10px; }
  .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input--phoneNumber {
    margin-left: 8px;
    height: 35px;
    padding-left: 15px;
    border: 1px solid transparent; }
  .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input-wrapper--phoneInput {
    display: flex;
    align-items: center; }
    .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input-wrapper--phoneInput:focus {
      border: 1px solid #06f;
      box-shadow: 0 0 0 2px #99c2ff; }
  .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__phoneNumber {
    display: flex;
    align-items: center;
    margin-left: 65px;
    width: 100%; }
  .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__countryFlag {
    position: absolute; }
  .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active {
    box-shadow: none; }
    .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active:hover {
      box-shadow: none; }
